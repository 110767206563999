<template>
  <div>
    <el-dialog
      title="LOGIN"
      :visible.sync="loginProps.isShown"
      width="50%"
      center>
      <el-alert
      :title="alertError"
      v-if="alertError"
      type="error">
    </el-alert>
      <el-form ref="form" :model="loginForm" label-width="80px">
        <el-form-item label="手机号">
          <el-input v-model="loginForm.phone" :disabled="disabledBntAval" placeholder="请输入电话号码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button flat @click="sendSMS" :disabled="disabledBntAval">{{smsText}}</el-button>
        </el-form-item>
        <el-form-item label="验证吗">
          <el-input v-model="loginForm.code" placeholder="请输入验证码" ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button color="primary" @click="login"> 登&nbsp;&nbsp;&nbsp;&nbsp;录 </el-button>
        <el-button @click="statusChange('close')">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Token from '@/utils/token'
export default {
  name: 'LoginDialog',
  props: ['loginProps'],
  data () {
    return {
      loginForm: {
        phone: '',
        code: ''
      },
      secondes: 60,
      interVal: null,
      smsText: '发送短信',
      alertError: '',
      disabledBntAval: false
    }
  },
  methods: {
    statusChange (status = 'close') {
      this.$emit('loginEmit', { status: status })
    },
    sendSMS () {
      if (!this.loginForm.phone) { return }
      this.$axios.post('/api/user/authsms', this.loginForm)
        .then(() => {
          this.disabledBntAval = true
          this.secondes = 60
          this.interVal = setInterval(() => {
            if (this.secondes > 0) { this.secondes -= 1 } else {
              this.disabledBntAval = false
              this.smsText = '发送短信'
              clearInterval(this.interVal)
            }
            this.smsText = this.secondes
          }, 1000)
        })
        .catch(err => {
          this.disabledBntAval = false
          console.log(err)
          this.alertError = err.messages[0]
          clearInterval(this.interVal)
        })
    },
    login () {
      if (!this.loginForm.phone || !this.loginForm.code) { 
        this.alertError = '手机号和验证码不能为空'
        return
        }
      this.$axios.post('/api/user/login', this.loginForm)
        .then(res => {
          Token.setToken(res.data.token, res.data.scope)
          this.statusChange('success')
        })
        .catch(err => { this.alertError = err.messages[0] })
        .finally(() => { this.disabledBntAval = false })
    }
  }
}
</script>
<style lang="stylus" scoped>
.login-box
  padding 25px
  width 100%
  .phone-box
    display flex
.login-box>div
  margin 20px
</style>
