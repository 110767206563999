<template>
  <div class="head-home">
    <el-menu default-active="1" class="el-menu-container" mode="horizontal" background-color="#000000" active-text-color="#FFFFFF" text-color="#909399">
      <div class="action-menu">
        <el-menu-item @click="go()" index="1">SEVEN</el-menu-item>
        <el-menu-item @click="go('tags')" index="2">TAGS</el-menu-item>
        <el-menu-item @click="go('me')" index="3">ME</el-menu-item>
      </div>
      <div class="login-profile">
        <profile v-if="isLogin"></profile>
        <div v-else @click="login" class="login"><i class="el-icon-user-solid">LOGIN</i></div>
      </div>
    </el-menu>
    <login-dialog
      v-if="loginProps.isShown"
      :login-props= "loginProps"
      @loginEmit="getLoginEmit">
      </login-dialog>
  </div>
</template>

<script>
import Token from '@/utils/token'
import Profile from '@/components/header/components/profile'
import LoginDialog from '@/components/logindialog/loginDialog'
export default {
  name: 'Header',
  components: {
    Profile,
    LoginDialog
  },
  data () {
    return {
      isLogin: false,
      loginProps: { isShown: false }
    }
  },
  methods: {
    go (url = '') {
      this.$router.push({ path: '/' + url })
    },
    getLoginEmit (recv) {
      this.isLogin = recv.status === 'success'
      this.loginProps.isShown = false
    },
    login () {
      this.loginProps.isShown = true
    }
  },
  mounted () {
    if (Token.getToken()) {
      this.isLogin = true
    }
  }
}
</script>
<style lang="stylus" scoped>
.head-home
  .el-menu-container
    position: fixed
    z-index: 99
    width: 100%
    display: flex
    justify-content: space-between
    font-weight: bold
    height: 60px
    .action-menu
      display: flex
      flex-grow: 2
      justify-content: flex-start
      margin-left: 20px
    .login-profile
      .login
        width: 100%
        height: 100%
        line-height: 60px
        cursor: pointer
        color: #FFFFFF
</style>
