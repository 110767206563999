<template>
  <el-dropdown trigger="click">
    <div class="icon-drop">
      <img :src="imageUrl" class="user-img" alt="user-img">
      <i class="el-icon-caret-bottom dropdown-icon"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item>设置</el-dropdown-item>
      <el-dropdown-item>
        <div @click="loginOut">退出</div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import Token from '@/utils/token'
import Avatar from '@/assets/images/avatar.jpg'
export default {
  name: 'Profile',
  data () {
    return {
      imageUrl: Avatar
    }
  },
  methods: {
    loginOut () {
      this.$axios
        .post('/api/home/loginout')
        .then(() => {
          this.isLogin = false
          Token.removeToken()
          this.$router.push({ path: '/' })
        })
        .catch(err => {
          console.log(err)
          this.isLogin = false
          Token.removeToken()
          this.$router.push({ path: '/' })
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.menu-icon
  cursor: pointer
  line-height: 60px
.icon-drop
  margin-bottom: 10px
  .user-img
    height 36px
    width 36px
    margin 7px 0 0 0
    object-fit cover
    border-radius 50%
  .dropdown-icon
    width: 100%
    text-align: center
</style>
